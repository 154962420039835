<template>
    <!-- 导航 -->
    <page-head title="梯队赛程"/>
    <div class="overview">
        <div class="overviewItem" v-for="item in overviewColor" :key="item.key"
             :style="{background: itemBg(item.colors)}" :class="item.key === overviewFilter ? 'check' : ''"
             @click="item.isClick ? overviewFilter = item.key : ''">
            <p class="label">{{ item.label }}</p>
            <p class="value">{{ overview[item.key] || 0 }}</p>
            <span class="checkLine" v-show="item.key === overviewFilter"></span>
        </div>
    </div>
    <div class="matchList">
        <div class="matchItem" v-for="item in showData.showList" :key="item.id" @click="matchDetail(item)">
            <p class="title">{{ item.title }}</p>
            <div class="itemContent">
                <div class="left">
                    <img v-if="item.court === 'home'"
                         :src="store.state.tenantData.logo || require('@i/firstMenu/xunlian.svg')" alt="">
                    <img v-else :src="item.againstLogo || require('@i/common/againstLogo.svg')" alt="">
                    <span v-if="item.court === 'home'">{{ store.state.teamData.name }}</span>
                    <span v-else>{{ item.against || '--' }}</span>
                </div>
                <div class="center">
                    <span class="score">{{ item.score }}</span>
                    <span class="date">{{ item.date }}  {{ item.start }}</span>
                    <span class="pitch">{{ item.pitch || '--' }}</span>
                </div>
                <div class="right">
                    <img v-if="item.court === 'away'"
                         :src="store.state.tenantData.logo || require('@i/firstMenu/xunlian.svg')" alt="">
                    <img v-else :src="item.againstLogo || require('@i/common/againstLogo.svg')" alt="">
                    <span v-if="item.court === 'away'">{{ store.state.teamData.name }}</span>
                    <span v-else>{{ item.against || '--' }}</span>
                </div>
            </div>
        </div>
    </div>
    <pagination-com :current-page="showData.page" :total-page="showData.totalPage"
                    @changePage="changePage"></pagination-com>

</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {computed, getCurrentInstance, onMounted, reactive, toRefs, watch} from "vue";
import {useStore} from "vuex";

export default {
    name: "index",
    setup() {

        const router = useRouter()
        const route = useRoute()
        const {proxy} = getCurrentInstance()
        const store = useStore()

        const state = reactive({
            overviewColor: [
                {
                    key: 'matches',
                    isClick: true, // 是否可以点击
                    isActive: true, // 是否获取焦点
                    value: 0,
                    label: '比赛总数',
                    colors: ['#608AFF', '#2E65FF'],
                    bgOpacity: '.08',
                },
                {
                    key: 'wins',
                    isClick: true,
                    isActive: false,
                    value: 0,
                    label: '胜',
                    colors: ['#77EE84', '#44DF59'],
                    bgOpacity: '.16',
                },
                {
                    key: 'loses',
                    isClick: true,
                    isActive: false,
                    value: 0,
                    label: '负',
                    color: '#FF8845',
                    colors: ['#FF7186', '#FF5574'],
                    bgOpacity: '.12',
                },
                {
                    key: 'draws',
                    isClick: true,
                    isActive: false,
                    value: 0,
                    label: '平',
                    color: '#FFC147',
                    colors: ['#FF876C', '#FF6A48'],
                    bgOpacity: '.12',
                },
                {
                    key: 'goals',
                    value: 0,
                    label: '进球',
                    colors: ['#5972EE'],
                },
                {
                    key: 'fumbles',
                    value: 0,
                    label: '丢球',
                    colors: ['#5972EE'],
                },
                {
                    key: 'reds',
                    value: 0,
                    label: '红牌',
                    colors: ['#5972EE'],
                },
                {
                    key: 'yellows',
                    value: 0,
                    label: '黄牌',
                    colors: ['#5972EE'],
                },
            ],
            overview: {},
            overviewFilter: 'matches',

            matchList: [],
            // 显示的菜单，分页
            page: {
                pageNum: 1,
                pageSize: 4
            },
            showData: {}
        })
        watch(() => state.overviewFilter, (val, old) => {
            if (val != old) {
                getTeamMatchOverview();
            }
        })

        const getTeamMatchOverview = () => {
            const {id} = store.state.teamData;
            let params = {
                teamId: id,
                type: state.overviewFilter,
                startDate: proxy.$Config.currentYearFirstDay,
                endDate: proxy.$moment().format('YYYY/MM/DD')
            }
            proxy.$server.getTeamMatchOverview(params)
                .then(res => {
                    state.overview = res.data
                })
            proxy.$server.getTeamMatchSchedule(params)
                .then(res => {
                    state.matchList = [];
                    for (const dataKey in res.data) {
                        state.matchList.push(
                            ...res.data[dataKey]
                        )
                    }
                    console.log(state.matchList)
                    state.showData = proxy.$utils.paginationFn(state.matchList, state.page.pageNum, state.page.pageSize);
                })
        }

        onMounted(() => {
            getTeamMatchOverview()
        })

        const itemBg = (value) => {
            return value.length > 1
                ? `linear-gradient(110.54deg, ${value[0]} 13.63%, ${value[1]} 88.76%)`
                : value[0];
        }


        const changePage = (page) => {
            state.page.pageNum = page;
            state.showData = proxy.$utils.paginationFn(state.matchList, state.page.pageNum, state.page.pageSize);
        }

        const matchDetail = (item) => {
            let path = '/teamMatch/details'

            router.push({
                path,
                query: {
                    id: item.id
                }
            })
        }

        return {
            ...toRefs(state),
            store,

            itemBg,
            changePage,
            matchDetail
        }
    }
}
</script>

<style scoped lang="scss">
.overview, .matchList {
    margin-left: 60px;
}

.overview {
    display: flex;
    margin-top: 24px;

    .overviewItem {
        width: 155px;
        height: 100px;
        border-radius: 8px;
        margin-right: 15px;
        color: rgba(255, 255, 255, .6);
        text-align: center;
        padding-top: 7px;
        box-sizing: border-box;
        position: relative;

        .label {
            font-size: 26px;
            line-height: 36px;
        }

        .value {
            font-size: 40px;
            line-height: 50px;
            font-family: akrobatBold;
        }

        &.check {
            .label {
                color: rgba(255, 255, 255, .8);
            }

            .value {
                color: rgba(255, 255, 255, 1);
            }
        }

        .checkLine {
            background: #FFFFFF;
            border-radius: 8px;
            width: 50px;
            height: 6px;
            position: absolute;
            left: calc((100% - 50px) / 2);
            bottom: -2px
        }

    }
}

.matchList {
    display: flex;
    flex-flow: wrap;
    margin-top: 20px;

    .matchItem {
        display: flex;
        flex-direction: column;
        width: 656px;
        height: 316px;
        background: linear-gradient(180deg, #12122E 0%, #151443 100%);
        box-shadow: 0px 32px 32px rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(40px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 8px;
        color: #fff;
        margin-bottom: 32px;
        margin-right: 32px;
        overflow: hidden;

        .title {
            height: 62px;
            line-height: 62px;
            background: linear-gradient(90deg, #162147 0%, #191C3A 100%);
            font-size: 26px;
            color: rgba(255, 255, 255, .8);
            border-bottom: 1px solid #4E5C8D;
            padding: 0 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        img {
            width: 88.6px;
            height: auto;
            margin-bottom: 10px;
        }

        .itemContent {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;

            .left, .right {
                flex: 1;
                padding: 0 20px;
                font-size: 25.2561px;

                span {
                    height: 70px;
                    line-height: 35px;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    display:-webkit-box;
                    -webkit-box-orient:vertical;
                    -webkit-line-clamp:2;
                }
            }

            .center {
                width: 30%;

                span {
                    display: block;
                }

                .score {
                    font-size: 72px;
                    line-height: 90px;
                    font-family: akrobatBold;
                    margin-bottom: 10px;
                }

                .date, .pitch {
                    color: rgba(255, 255, 255, .8);
                }

                .date {
                    font-size: 28px;
                    font-family: akrobatRegular;
                    line-height: 35px;
                    margin-bottom: 2px;
                }

                .pitch {
                    line-height: 35px;
                    font-size: 24px;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    display:-webkit-box;
                    -webkit-box-orient:vertical;
                    -webkit-line-clamp:2;
                }
            }
        }
    }

}

::v-deep.pagination {
    position: absolute;
    left: 592px;
    top: 978px;
}
</style>